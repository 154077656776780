











































import BaseDialog from "../../Base/BaseDialog.vue";
import BaseCard from "@/components/Base/BaseCard.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { loadFile } from "@/helpers/useLoader";

@Component({
    components: {
        BaseCard,
        BaseDialog,
    },
})
export default class SharedCompaniesProfilePhotos extends Vue {
    @Prop() images!: any[];

    public selectedImagePath: string = "";
    public showDialog: boolean = false;

    get hasImages() {
        return this.images && this.images.length;
    }

    loadFile(path: string) {
        return loadFile(path);
    }

    mounted() {
        this.images.map((image: any) => {
            console.log("a:", image);
        });
    }

    get hasImage(): boolean {
        let hasImage: boolean = false;

        if (!!this.selectedImagePath) hasImage = true;

        return hasImage;
    }
}
